import React from "react";
import { Layout, Seo } from "components/common";
import { Intro, Skills, Contact, Projects } from "components/landing";
import { Grid } from "../components/landing/Projects/styles"
import Folio from '../components/common/folioLayout/folio'
import "./style.css"
import { StaticImage } from "gatsby-plugin-image"

export const Portraits = () => {
    return (
        <>
            <div class="gre">
                <Layout>
                    <Seo />
                    <Folio pageTitle="Portrait Pixls" underLine="portraits">
                        {/* <Grid> */}
                        <div class="row">
                            <div class="column">
                                <StaticImage src='../images/portraits/1.JPG' layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/2.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/3.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/12.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/13.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/28.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/31.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src="../images/portraits/4.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/5.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/14.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/15.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/17.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/29.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/32.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src='../images/portraits/6.JPG' layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/7.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/18.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/19.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/27.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/21.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src="../images/portraits/22.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/23.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/24.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/25.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/26.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/20.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/portraits/30.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                        </div>
                        {/* </Grid> */}
                    </Folio>

                </Layout>
            </div>
        </>
    );
};


export default Portraits;